<template>
  <PriceStructView :quote-obj='rows' :platform-type='platformType'></PriceStructView>
</template>
<script>

import PriceStructView from '@/pages/publication/compoents/PriceStructView'

export default {
  name: 'PriceGrids',
  components: { PriceStructView },
  props: {
    rows: {
      type: [Array, Object],
      default() {
        return []
      }
    },
    platformType: {
      type: String,
      default() {
        return null
      }
    },
  }
}
</script>

<style scoped>
.price-panel {
  width: 100%;
  /*border: #fe346e 1px dashed;*/
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start;

}
</style>
