<template>
  <div style='width: 100%;' class='flex-sb' v-if='priceTypeMap'>
    <div class='price-panel' v-if='priceTypeMap.mixed'>
      <!--    报价信息-混合（富文本）-->
      <div style='width: 100%;' v-if='quoteObj.mixed'>
        <div class='price-panel'>
          <div class='price-mixed' v-if='quoteObj.mixed.sri' style='width: 100%'>
            <div>
              <span class='price-title'>{{ quoteObj.mixed.sri.label || '' }}</span>
            </div>
            <div v-html='quoteObj.mixed?.sri.val'></div>
          </div>

          <div class='price-mixed' v-if='quoteObj.mixed.mfr' style='width: 100%'>
            <div>
              <span class='price-title'>{{ quoteObj.mixed.mfr.label || '' }}</span>
            </div>
            <div v-html='quoteObj.mixed.mfr.val'></div>
          </div>
        </div>

<!--        <div class='price-mixed' v-if='quoteMixedPfcCols'>-->
<!--          <div>-->
<!--            <span class='price-title'>{{ quoteObj.mixed.pfc.label || '' }}</span>-->
<!--          </div>-->
<!--          <div v-if='quoteMixedPfcCols'>-->
<!--            <el-table v-if='quoteMixedPfcCols' key='tableMulti' ref='refTableMulti' :data='quoteObj.mixed.pfc.val'-->
<!--                      :show-header='true' border>-->
<!--              <template v-for='(col,index) in quoteMixedPfcCols'>-->
<!--                <el-table-column :label='col.title' :prop='col.field' align='center'-->
<!--                                 :key='`${col.field}_${index}`' min-width='160'>-->
<!--                  <template slot-scope='{row,column}'>-->
<!--                    <div>-->
<!--                      <span>{{ row[column.property] }}</span>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </el-table-column>-->
<!--              </template>-->
<!--            </el-table>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

    <!--    报价信息-指定项-->
    <div class='price-panel' v-if='priceTypeMap.single' style='width: 100%'>
      <div v-if='quoteObj.single' style='width: 100%'>
        <el-table key='tableSingle' ref='refTableSingle' :data='quoteObj.single' v-if='quoteObj.single.length>0'
                  :show-header='false'
                  highlight-current-row border>
          <el-table-column label='报价类目' prop='title' align='center' min-width='180'>
          </el-table-column>
          <el-table-column label='报价金额' prop='val' align='center' min-width='140'>
            <template slot-scope='{row}'>
              <div :class='calcHasNewValClass(row.newVal,row.val)'>
                {{ row.val ? moneyFormat(row.val) : '/' }}
                <div v-if='row.remark' style='color: red;'>{{ row.remark || '--' }}</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!--    报价信息-表格-->
    <div class='price-panel' v-if='priceTypeMap.multi'>
      <div v-if='quoteMultiCols.cols && quoteMultiCols.cols.length>0' style='width: 100%'>
        <div><span class='price-title'>{{ quoteMultiCols.label || '' }} </span></div>
        <el-table key='tableMulti' ref='refTableMulti' :data='quoteObj.multi' :show-header='true' border>
          <template v-for='(col,index) in quoteMultiCols.cols'>
            <el-table-column :label='col.title' :prop='col.field' align='center'
                             :key='`${col.field}_${index}`' :min-width='col.width'>
              <template slot-scope='{row,column}'>
                <div>
                  <span>{{ row[column.property] }}</span>
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import plcMap from '@/json/plc.json'
// 报价信息-数据结构
const quoteStruct = plcMap.form.quoteStruct

export default {
  name: 'PriceStructView',
  props: {
    quoteObj: {
      type: Object,
      default() {
        return quoteStruct
      }
    },
    field: {
      type: String,
      default() {
        return 'next_quote'
      }
    },
    platformType: {
      type: String,
      default() {
        return null
      }
    },
    showRemark: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    quoteMultiCols() {

      return plcMap.quoteInfo.multi[this.platformType] || { 'label': '报价信息', 'cols': [] }
    },
    // quoteMixedPfcCols() {
    //   return plcMap.form.quoteStruct.mixed.pfc.cols
    // },
    //报价方式：品类报价（1：n）；类目报价
    priceTypeMap() {
      let customPriceMap = plcMap.customPriceMap

      return customPriceMap[this.platformType]
    }
  },
  methods: {
    moneyFormat(val) {
      return this.$utils.numberFormat(val, 0, '.', ',')
    },

    calcChange(newVal, val) {
      if (newVal > val) {
        return 'el-icon-top up'
      } else if (newVal < val) {
        return 'el-icon-bottom down'
      } else
        return ''
    },
    calcNewValClass(newVal, val) {
      if (newVal > val) {
        return 'up'
      } else if (newVal < val) {
        return 'down'
      } else
        return 'equal'
    },
    calcHasNewValClass(newVal, val) {
      if (newVal > val) {
        return 'has'
      } else if (newVal < val) {
        return 'has'
      } else
        return ''
    }
  }
}
</script>

<style scoped>
.price-panel {
  width: 100%;
  /*border: #f2f2f2 1px solid;*/
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start;

}

.price-mixed {
  border: #f2f2f2 1px solid;
  padding: 10px 10px;
  border-radius: 5px;
  width: auto;
  margin: 5px auto;
}

.price-title {
  font-size: 14px !important;
  font-weight: bold !important;
  padding-left: 10px;
  line-height: 24px;
  /*display: none;*/
}

</style>
